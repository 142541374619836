<template>
    <div class="selectComponent" v-if="show">
        <div class="list-group option-lists">
            <div class="group" v-for="(items, index) in option" :key="index">
                <div 
                    class="group-title"
                    :class="{'additional-info' : items.additional}"
                >
                    {{ items.occupation_group }}
                </div>
                <div 
                    class="group-item" 
                    v-for="(item, idx) in items.occupation" 
                    :key="idx"
                    @click="selected(item)"
                >
                    <span v-if="language === 'id'">{{item.name_th}}</span>
                    <span v-else>{{item.nameEn}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SelectComponent",
    props: {
        option: {
            type: Array,
            default: () => [],
        },
        show: {
            type: Boolean,
            default: false
        },
        language: {
            type: String
        }
    },
    methods: {
        selected(value) {
            const newValue = {
                nameTh: value.name_th,
                apiName: value.apiName,
                nameEn: value.nameEn,
                id: value.id,
                occupationGroup: value.occupationGroup
            };
            this.$emit("selectData", newValue);
        }
    }
}
</script>
<style scoped>
.selectComponent {
    position: absolute;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    top: 50px;
    z-index: 1;
    box-shadow: -1px 3px 11px -2px rgba(133,133,133,0.75);
    -webkit-box-shadow: -1px 3px 11px -2px rgba(133,133,133,0.75);
    -moz-box-shadow: -1px 3px 11px -2px rgba(133,133,133,0.75);
    height: 300px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.selectComponent::-webkit-scrollbar {
    display: none; 
}

.selectComponent .group-title {
    background-color: #F5F5F5;
    padding: 12px 15px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}
.selectComponent .group-title::before {
    content: "•"; 
    color: black;
    display: inline-block; 
    width: 1em;
}
.selectComponent .additional-info {
    background-color: #FFFFFF;
    padding: 12px 15px;
    color: #ff5011;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    width: 97%;
    overflow: hidden;
}
.selectComponent .additional-info::before {
    content: "";
    margin-left: -1rem;
}
.selectComponent .additional-info::after {
    position: absolute;
    content: "";
    height: 2px;
    background-color: #ff5011;
    width: 100%;
    margin-left: 15px;
    top: 50%;
}
.selectComponent .group-item {
    color: #01549B;
    margin: 8px 15px;
    border-bottom: 1px solid #F5F5F5;
    padding: 8px 0px;
    cursor: pointer;
}

@-webkit-keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-15%);
        transform: translateY(-15%);
        visibility: visible;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(15%);
        transform: translateY(-15%);
        visibility: visible;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
</style>