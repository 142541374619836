<style>
body.default-style #pa-input.questions>.container {
  padding: 0 15px;
}

body.default-style #pa-input .question-mark, body.default-style #pa-input .question-mark:active, body.default-style #pa-input .question-mark:focus  {
  /* position: absolute; */
  right: .5vw;
  top: 0px;
  padding: 0;
  margin: 0;
  width: 20px;
  min-width: 20px;
  line-height: 20px;
  background-color: #fff;
  color: #ff5011;
  display: block;
  border-radius: 50%;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  border: 0 solid rgba(255,255,255,0);
  -moz-box-shadow: #ff5011 0 1px 5px 0 !important;
  box-shadow: #ff5011 0 1px 5px 0 !important;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  text-decoration: none;
}

#pa-input picture img{
  max-width: 100%;
}

#pa-input .to2do-new {
  color: #EF4A24;
  border: 1px solid #EF4A24!important;
}

#pa-input .to2do-new:focus {
  border-color: #80bdff !important;
}

#pa-input{
  margin-top: 0px;
}

#pa-input .sex2status .choose4yes {
  border: 1px solid #01549b!important;
  background-color: #01549b!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  color: #fff!important;
}

#pa-input .sex2status .choose4no {
  border: 1px solid #EF4A24!important;
  background-color: #fff;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  color: #EF4A24!important;
}

#pa-input .sex2status {
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  -webkit-box-pack: CENTER;
  -ms-flex-pack: CENTER;
  justify-content: CENTER;
  gap: 10px 10px;
}

@media (max-width: 629px){
  #pa-input .sex2status {
    grid-template-columns: 48% 48%;
  }

  #pa-input .sex2status .btn {
    height: 150px;
  }
}

@media (max-width: 350px){
  #pa-input .sex2status {
    grid-template-columns: 100%;
  }
}

#pa-input .sex2status .btn {
  height: 40px!important;
  padding-bottom: 175px!important;
  padding: 30px 15px 120px;
  font-size: 18px;
  font-weight: 700;
  background-repeat: no-repeat;
  background-position: bottom;
  display: block;
  line-height: 16px;
  text-indent: 0;
  border-radius: 5px;
  color: #EF4A24;
  border: 1px solid #ccc;
  box-shadow: none;
}

#pa-input .btn-next {
  font-size: 20px;
  color: #fff;
  border-radius: 5px;
  background-image: none;
  background-color: #ff5011;
  text-align: left;
  padding: 15px 40px;
  margin: 10px auto;
  box-shadow: 1px 1px 6px 1px rgb(255 80 17 / 15%);
}

#pa-input .btn-next.disabled {
  border: 1px solid #ccc!important;
  color: #ccc!important;
  background-color: #fff!important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0/15%)!important;
}

#pa-input .btn-next:hover{
  box-shadow: none!important;
}

#pa-input .DOBdiv {
  display: grid;
  grid-template-columns: 25% 25% 40%;
  justify-content: CENTER;
  gap: 10px 5px;
}

@media (max-width: 629px) {
  #pa-input .DOBdiv {
    grid-template-columns: 25% 25% 40%;
  }
}

#pa-input .DOBdiv .dob2extra {
  font-size: 22px;
  font-weight: 700;
  padding: 25px 10px;
}

#pa-input .DOBdiv .dob2extra::placeholder  {
	font-size: 22px;
  font-weight: bold;
  padding: 25px 10px;
  color:#CCC;
}

#pa-input #selected-occupation-btn {
  font-size: 16px;
}


#pa-input #input-income .noUi-target, #pa-input #input-income .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#pa-input #input-income .noUi-target {
  position: relative;
  background: #eae7e7;
  border-radius: 8px;
  border: 0px;
  box-shadow: none;
  -webkit-box-shadow: none;
}
#pa-input #input-income .noUi-base,
#pa-input #input-income .noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
#pa-input #input-income .noUi-connects {
  overflow: hidden;
  z-index: 0;
}
#pa-input #input-income .noUi-connect,
#pa-input #input-income .noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
#pa-input #input-income .noUi-connect {
  height: 100%;
  width: 100%;
}
#pa-input #input-income .noUi-origin {
  height: 10%;
  width: 10%;
}
#pa-input #input-income .noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
#pa-input #input-income .noUi-vertical .noUi-origin {
  width: 0;
}
#pa-input #input-income .noUi-horizontal .noUi-origin {
  height: 0;
}
#pa-input #input-income .noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
#pa-input #input-income .noUi-touch-area {
  height: 100%;
  width: 100%;
}
#pa-input #input-income .noUi-state-tap .noUi-connect,
#pa-input #input-income .noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
#pa-input #input-income .noUi-state-drag * {
  cursor: inherit !important;
}
#pa-input #input-income .noUi-horizontal {
  height: 18px;
}
#pa-input #input-income .noUi-horizontal .noUi-handle {
  width: 28px;
  height: 28px;
  right: -12px;
  top: -5px;
}
#pa-input #input-income .noUi-vertical {
  width: 18px;
}
#pa-input #input-income .noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}
#pa-input #input-income .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}
#pa-input #input-income .noUi-connects {
  border-radius: 8px;
}
#pa-input #input-income .noUi-connect {
  background: #ff5011;
  border-radius: 8px;
}
#pa-input #input-income .noUi-draggable {
  cursor: ew-resize;
}
#pa-input #input-income .noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
#pa-input #input-income .noUi-handle {
  border: 1px solid #f7f1f1;
  border-radius: 25px;
  background: #ff5011;
  cursor: default;
  box-shadow: none;
  -webkit-box-shadow: none;
}
#pa-input #input-income .noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
#pa-input #input-income .noUi-handle:before,
#pa-input #input-income .noUi-handle:after {
  content: "";
  display: none;
}
#pa-input #input-income .noUi-handle:after {
  left: 17px;
}
#pa-input #input-income .noUi-vertical .noUi-handle:before,
#pa-input #input-income .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
#pa-input #input-income .noUi-vertical .noUi-handle:after {
  top: 17px;
}
#pa-input #input-income [disabled] .noUi-connect {
  background: #B8B8B8;
}
#pa-input #input-income [disabled].noUi-target,
#pa-input #input-income [disabled].noUi-handle,
#pa-input #input-income [disabled] .noUi-handle {
  cursor: not-allowed;
}
#pa-input #input-income .noUi-pips,
#pa-input #input-income .noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#pa-input #input-income .noUi-pips {
  position: absolute;
  color: #999;
}
#pa-input #input-income .noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
#pa-input #input-income .noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
#pa-input #input-income .noUi-marker {
  position: absolute;
  background: #CCC;
}
#pa-input #input-income .noUi-marker-sub {
  background: #AAA;
}
#pa-input #input-income .noUi-marker-large {
  background: #AAA;
}
#pa-input #input-income .noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
#pa-input #input-income .noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  margin-top: 10px;
}

#pa-input #input-income .noUi-value:last-child{
  margin-left:-30px;
}

#pa-input #input-income .noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
#pa-input #input-income .noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
#pa-input #input-income .noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
#pa-input #input-income .noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
#pa-input #input-income .noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
#pa-input #input-income .noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
#pa-input #input-income .noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
#pa-input #input-income .noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
#pa-input #input-income .noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
#pa-input #input-income .noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
#pa-input #input-income .noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
#pa-input #input-income .noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
#pa-input #input-income .noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
#pa-input #input-income .noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
#pa-input #input-income .noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

#pa-input #input-income .noUi-value{
  margin-top: 10px;
}

#pa-input #input-income #slider-range{
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  margin-top:20px;
}

#pa-input #input-income #slider-range > .range{
  color: #003b71; text-align: center;
}

#pa-input #input-income #slider-range > .range:first-child{
  text-align: left;
}

#pa-input #input-income #slider-range > .range:last-child{
  text-align: right;
}
.choose4no {
  border: 1px solid #EF4A24 !important;
  background-color: #FFF;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #EF4A24 !important;
}

#pa-input .usage4button {
  width: 100%!important;
  text-indent: 0px;
  /* border: 1px solid #ff5011 !important; */
  background-image: none;
  min-height: 60px;
  height: auto!important;
  line-height: 28px;
  display: inline-block;
  white-space: normal;
  margin: 0px auto 15px;
  border-radius: 5px;
  color: #EF4A24;
  border: 1px solid #EF4A24;
  box-shadow: none;
  background-color: #FFF;
}

#pa-input .usage4button.choose4no {
  border: 1px solid #EF4A24!important;
  color: #EF4A24!important;
}


#pa-input .buy-now {
  font-size: 1.6rem;
  text-decoration: none;
}

#pa-input .animated-qv3 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite
}

@-webkit-keyframes swing-qv3 {
  20% {
      -webkit-transform: rotate(15deg)
  }
  40% {
      -webkit-transform: rotate(-10deg)
  }
  60% {
      -webkit-transform: rotate(5deg)
  }
  80% {
      -webkit-transform: rotate(-5deg)
  }
  100% {
      -webkit-transform: rotate(0)
  }
}
@keyframes swing-qv3 {
  20% {
      transform: rotate(15deg)
  }
  40% {
      transform: rotate(-10deg)
  }
  60% {
      transform: rotate(5deg)
  }
  80% {
      transform: rotate(-5deg)
  }
  100% {
      transform: rotate(0)
  }
}

#pa-input .swing-qv3 {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing-qv3;
  animation-name: swing-qv3
}

#pa-input .tooltip2extra {
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  color: #015CAF;
}

#pa-input .tooltip2extra .tooltip2extra {
  width: 100%;
  background-color: #fff;
  border: 1px solid #015CAF;
  padding: 15px 10px;
  border-radius: 4px;
  font-weight: 500;
}

#pa-input .tooltip2extra .arrow2extra {
  position: relative;
  width: 30px;
  height: 15px;
  background-color: #fff;
  margin: -1px auto 0;
}

#pa-input .tooltip2extra .arrow2extra:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #FFF;
  top: -1px;
  left: 0px;
  z-index: 5;
}

#pa-input .tooltip2extra .arrow2extra:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #015CAF;
  top: 0px;
  left: 0px;
}

#pa-input #progress-line-area {
  height: 20px;
  margin-bottom: 0px;
  border-radius: 0;
  margin-top: 5px;
  width: 100%;
}

#pa-input #progress-line-area.fixed{
  position: fixed;
  margin-top: 0;
  left: 0;
  top: 0;
  z-index: 1100;
}

#pa-input #progress-line-area .progress {
  border-radius: 0;
  height: 20px;
  margin-bottom: 0px;
  -webkit-box-shadow: inset 1px 1px 6px 1px rgb(0 0 0 / 15%);
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 15%);
}
#pa-input .select-option .dropdown-menu.basic{
  height: 40vh !important;
}

#pa-input #progress-line-area .progress .progress-bar {
  background-image: linear-gradient(to right, rgba(1,92,175,1), rgba(71,167,255,1));
}
.opened svg {
  rotate: 180deg;
  transition: 0.3s;
}
.occupationListContainer {
  position: absolute;
  width: 100%;
  z-index: 999;
}
.occupationListContainer .selectOptions {
  padding: 15px;
  background: #fff;
  width: 100%;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border-radius: 4px;
  max-height: 325px;
  position: absolute;
  top: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  border: 1px #fbfbfb solid;
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-15%);
        transform: translateY(-15%);
        visibility: visible;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

</style>
<template>
  <div id="pa-input" class="questions" style="min-height: 90vh;">
    <div id="progress-line-area" class="text-center px-0 sticky-top">
      <div class="progress">
          <div class="progress-bar" id="extra2progress" :style="'width:' + progressBar + '%;'"></div>
      </div>
    </div>

    <div class="container" style="margin-top: 6rem;">
      <div class="row mb-5">
        <div class="col">
          <h1 class="h3 text-center">{{ $t("title") }}</h1>
        </div>
      </div>
      <div class="row mt-0" id="info-gender-marital" v-bind:class="{ incomplete: !Global.steps.includes('gender') }">
        <div class="col">
          <h2 class="h5 text-center text-default mb-3 mr-2 ml-2">
            <span class="text-center">
              {{ $t("maritalStatus") }}
            </span>
            <a class="question-mark" id="tooltip-first" data-toggle="tooltip" data-placement="top" :title="$t('tooltip.maritialStatus')" :data-original-title="$t('tooltip.maritialStatus')">?</a>
          </h2>
          <div class="sex2status">
            <button
              class="btn"
              style="background-image: url(images/sman.png);"
              v-bind:class="{'choose4yes': sex2status == 'MaleSingle', 'choose4no': (sex2status != '' && sex2status != 'MaleSingle'), 'to2do-new': sex2status == ''}"
              @click="setGender('Male', 'Single'); eventLog('Gender_status', 'Male-Single');"
              data-selenium-name="gender"
              data-selenium-value="Male-Single">
              {{ $t("maritalStatus_singleman") }}
            </button>
            <button
              class="btn"
              style="background-image: url(images/mman.png);" 
              v-bind:class="{'choose4yes': sex2status == 'MaleMarried', 'choose4no': (sex2status != '' && sex2status != 'MaleMarried'), 'to2do-new': sex2status == ''}"
              @click="setGender('Male', 'Married'); eventLog('Gender_status', 'Male-Married');"
              data-selenium-name="gender"
              data-selenium-value="Male-Married">
              {{ $t("maritalStatus_marriedman") }}
            </button>
            <button
              class="btn"
              style="background-image: url(images/swoman.png);" 
              v-bind:class="{'choose4yes': sex2status == 'FemaleSingle', 'choose4no': (sex2status != '' && sex2status != 'FemaleSingle'), 'to2do-new': sex2status == ''}"
              @click="setGender('Female', 'Single'); eventLog('Gender_status', 'Female-Single');"
              data-selenium-name="gender"
              data-selenium-value="Female-Single">
              {{ $t("maritalStatus_singlewoman") }}
            </button>
            <button
              class="btn"
              style="background-image: url(images/mwoman.png);" 
              v-bind:class="{'choose4yes': sex2status == 'FemaleMarried', 'choose4no': (sex2status != '' && sex2status != 'FemaleMarried'), 'to2do-new': sex2status == ''}"
              @click="setGender('Female', 'Married'); eventLog('Gender_status', 'Female-Married');"
              data-selenium-name="gender"
              data-selenium-value="Female-Married">
              {{ $t("maritalStatus_marriedwoman") }}
            </button>
          </div>
        </div>
      </div>

      <div class="row incomplete" id="input-dob" v-show="stepCompleted('gender')">
        <div class="col">
          <h2 class="h5 text-center text-default mb-3 mr-2 ml-2">
            <span class="text-center">
              {{ $t("dob") }}
            </span>
            <a href="javascript:void(0)" class="question-mark" data-toggle="tooltip" data-placement="top" :title="$t('tooltip.dob')" :data-original-title="$t('tooltip.dob')">?</a>
          </h2>
          <div class="text-center mb-4">
            <picture>
              <source media="(min-width: 1280px)" srcset="images/cake2x.png">
              <img loading="lazy"  src="images/cake.png" alt="date of birth" class="">
            </picture>
          </div>

          <div class="DOBdiv mb-4">
            <div class="dd-dob">
              <input id="dd-dob" placeholder="02" v-number-only v-model.trim="dobInputDate" @blur="handleBlurDate" data-selenium-name="dd-dob" autocomplete="on" type="tel" required="required" maxlength="2" class="form-control input-lg dob2extra text-center" v-bind:class="{'to2do-new': !isDOBvalid}">
              <div class="mt-2 text-center">{{$t("dobDate")}}</div>
            </div>
            <div class="mm-dob">
              <input id="mm-dob" placeholder="09" v-number-only v-model.trim="dobInputMonth" @blur="handleBlurMonth" data-selenium-name="mm-dob" autocomplete="on" type="tel" required="required" maxlength="2" class="form-control input-lg dob2extra text-center" v-bind:class="{'to2do-new': !isDOBvalid}">
              <div class="mt-2 text-center">{{$t("dobMonth")}}</div>
            </div>
            <div class="yyyy-dob">
              <input id="yyyy-dob" placeholder="1980" v-number-only v-model.trim="dobInputYear" data-selenium-name="yyyy-dob" autocomplete="on" type="tel" required="required" maxlength="4" class="form-control input-lg dob2extra text-center" v-bind:class="{'to2do-new': !isDOBvalid}"  v-on:keyup.enter="isDOBvalid ? completeQuestion().dob() : ''">
              <div class="mt-2 text-center">{{$t("dobYear")}}</div>
            </div>
          </div>

          <div class="text-default text-center mb-4" v-if="stepCompleted('dob') && !isDOBvalid">
              <span class="glyphicons glyphicons-warning-sign"></span> {{$t("pleaseSelectAValidDate")}}
          </div>

          <div class="text-center" v-if="!stepCompleted('dob')">
              <button
                class="btn btn-lg btn-next"
                v-bind:class="{'disabled': !isDOBvalid}"
                @click="completeQuestion().dob();"
                data-selenium-name="dob-next"
                :disabled="!isDOBvalid">
                {{ $t("next") }} &#8595;
              </button>
          </div>
        </div>
      </div>

      <div class="row incomplete" id="input-occupation" v-show="stepCompleted('dob')">
        <div class="col-12 mb-5">
          <h2 class="h5 text-center text-default mb-3 mr-2 ml-2">
            <span class="text-center">
              {{ $t("occupation") }}
            </span>
            <a href="javascript:void(0)" class="question-mark" data-toggle="tooltip" data-placement="top" :title="$t('tooltip.occupation')" :data-original-title="$t('tooltip.occupation')">?</a>
          </h2>

          <!-- <div class="select-option dropdown mb-3" v-if="Global.quote.abTestingVersion === 'B'">
            <button id="selected-occupation-btn" 
              data-selenium-name="selected-occupation" 
              :data-selenium-value="phaccountOccupation ? phaccountOccupation.nameEn : ''" 
              class="btn btn-block btn-lg select-btn" 
              v-bind:class="{'selected' : Object.keys(Global.phaccount.occupation).length !== 0}" 
              type="button" 
              @click="openOccupation"
            >
              {{Object.keys(Global.phaccount.occupation).length !== 0 ? Global.quote.prefLang == 'id' ? Global.phaccount.occupation.nameTh : Global.phaccount.occupation.nameEn : $t("chooseOccupation")}}
              <div 
                class="btn-arrow"
                :class="{opened : optionOccupation}"
              >
                <svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="pull-right">
                  <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                </svg>
              </div>
            </button>
            <NewSelect 
              :option="occupationV2Data"
              :show="optionOccupation"
              :language="Global.quote.prefLang"
              @selectData="selectOcuppation"
            />
          </div> -->

          <div class="select-option dropdown mb-3">
            <button @click="showOccupationList = !showOccupationList" id="selected-occupation-btn" data-selenium-name="selected-occupation" :data-selenium-value="phaccountOccupation ? phaccountOccupation.nameEn : ''" class="btn btn-block btn-lg select-btn" v-bind:class="{'selected' : Object.keys(Global.phaccount.occupation).length !== 0}" type="button">
              {{Object.keys(Global.phaccount.occupation).length !== 0 ? Global.quote.prefLang == 'id' ? Global.phaccount.occupation.nameTh : Global.phaccount.occupation.nameEn : $t("chooseOccupation")}}
              <div class="btn-arrow" :class="{'opened': showOccupationList}">
                <svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="pull-right">
                  <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                </svg>
              </div>
            </button>
            <div class="occupationListContainer" v-if="showList">
              <div :class="{'selectOptions' : showList}">
                <div class="mx-auto mb-3" v-for="(item, index) in sortingOccupation" v-bind:key="index">
                  <a class="text-wrap" data-dismiss="modal" href="javascript:void(0);" data-selenium-name="occupation-item" :data-selenium-value="item.nameEn" @click="phaccountOccupation = item; showOccupationList = false;" >{{Global.quote.prefLang == 'id' ? item.nameTh : item.nameEn}}</a>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center" v-if="!stepCompleted('occupation')">
            <button class="btn btn-next btn-lg" v-bind:class="{'disabled': Object.keys(Global.phaccount.occupation).length == 0}" :disabled="Object.keys(Global.phaccount.occupation).length == 0" @click="completeQuestion().occupation();" data-selenium-name="occupation-next">
              {{ $t("next") }} &#8595;
            </button>
          </div>
        </div>
      </div>


      
      <div class="row incomplete" id="input-income" v-show="stepCompleted('occupation')">
        <div class="col-12 mb-5">
          <h2 class="h5 text-center text-default mb-3 mr-2 ml-2">
            <span class="text-center">
              {{ $t("yourIncome") }}
            </span>
            <a href="javascript:void(0)" class="question-mark" data-toggle="tooltip" data-placement="top" :title="$t('tooltip.income')" :data-original-title="$t('tooltip.income')">?</a>
          </h2>

          <div class="text-center mb-4">
            <picture>
              <source media="(min-width: 1280px)" srcset="images/salary2x.png">
              <img loading="lazy" src="images/salary.png" alt="Your income">
            </picture>
          </div>

          <div id="income-range" data-selenium-name="selected-income" :data-selenium-value="Global.quote.salary" class="h6 text-default my-4 text-center">
            <span v-if="Global.quote.salary > 0" key="selectedSalaryRange" style="line-height:33px">{{selectedSalaryRange ? selectedSalaryRange : $t("chooseYourSalaryRange")}}</span>
            <div v-else style="font-size: 14px; margin:0 0 0 -10px">
              <span key="selectedSalaryRange">{{$t("dragOrTouch")}}</span><br>
              <span class="glyphicons glyphicons-arrow-down animated-qv3 swing-qv3 text-default" style="font-size: 16px; margin:1px 0 0 0"></span>
            </div>
          </div>

          <div id="slider-income"></div>
          <div id="slider-range">
            <div class="range">0</div>
            <div class="range">20.000.000</div>
            <div class="range">
              <i18n-t tag="span" keypath="andMore">
              </i18n-t><br/>85.000.000
            </div>
          </div>
          <div>
            <input type="text" style="color:transparent; width:1px; height:1px; font-size:1px; border:0px;" data-selenium-name="slider-income-input" id="slider-income-input" v-model.trim="seleniumIncome" />
          </div>
          <div class="text-center mt-5" v-if="!stepCompleted('income') || Global.quote.salary == '0'">
            <button class="btn btn-next btn-lg" @click="completeQuestion().income();" data-selenium-name="income-next">
              {{ Global.quote.salary && Global.quote.salary != '0' ? $t("next") : $t("noIncome") }} &#8595;
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="row incomplete" id="detail-postcode" v-show="stepCompleted('income')">
          <div class="col-12" v-if="Global.quote.abTestingVersion === 'A' || Global.quote.abTestingVersion == ''">
            <h2 class="h5 text-center mb-3 text-default">{{$t("postcodeBasic")}}</h2>
            <a href="javascript:void(0)" class="question-mark" data-toggle="tooltip" data-placement="top" :title="$t('tooltip.postcode')" :data-original-title="$t('tooltip.postcode')">?</a>
            <div class="form-group">
              <input type="tel" class="form-control input-lg" v-number-only data-selenium-name="postalCode" v-bind:class="[Global.isValidated.postcode ? 'text-center' : 'to2do']"  maxlength="5" id="postcode" v-model.trim="Global.quote.postalCode" autocomplete="on" spellcheck="false" @input="triggerPostcode($event)">

              <span class="glyphicons glyphicons-validate" v-if="Global.quote.postalCode.length === 5" v-bind:class="giconsValidate( Global.isValidated.postcode )"></span>
            </div>
            <div class="orange-text text-center" v-if="!Global.isValidated.postcode && Global.quote.postalCode.length === 5">
              <span class="glyphicons glyphicons-validate glyphicons-warning-sign animated swing"></span> {{$t("pleaseInsertAZipCode")}}
            </div>
          </div>
          <div class="col-12 mb-5" id="detail-address-province" v-if="Global.quote.abTestingVersion === 'B'">
            <h2 class="h5 text-center mb-3 text-default">{{$t("province")}}</h2>
            <a href="javascript:void(0)" class="question-mark" data-toggle="tooltip" data-placement="top" :title="$t('tooltip.postcode')" :data-original-title="$t('tooltip.postcode')">?</a>

            <div class="select-option dropdown mb-3">
              <button id="detail-province-btn" data-selenium-name="selected-province" :data-selenium-value="Global.phaccount.province" class="btn btn-block btn-lg select-btn" v-bind:class="{'selected' : Global.phaccount.province}"  type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{Object.entries(Global.province).length > 0 ? (['id', 'en'].includes(Global.quote.prefLang) ? Global.province.name : Global.province.name) : $t("select")}}
                <div class="btn-arrow"><svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="pull-right"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg></div>
              </button>

              <div id="detail-province-options" aria-labelledby="detail-province-btn" class="dropdown-menu basic">
                <a v-for="(province, index) in listprovince" v-bind:key="'privince-' + index" data-selenium-name="province" :data-selenium-value="province.name" class="dropdown-item" href="javascript:void(0);" @click="completeQuestion().province(province)">{{['id', 'en'].includes(Global.quote.prefLang) ? province.name : province.name}}</a>
              </div>
            </div>
          </div>
          <div class="col-12 mb-5" id="detail-address-city" v-show="stepCompleted('detail-province')" v-if="Global.quote.abTestingVersion === 'B'">
            <h2 class="h5 text-center mb-3 text-default">{{$t("city")}}</h2>
            <div class="select-option dropdown mb-3">
              <button id="detail-city-btn" data-selenium-name="selected-city" :data-selenium-value="Global.phaccount.city" class="btn btn-block btn-lg select-btn" v-bind:class="{'selected' : Global.phaccount.city}"  type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{Object.entries(Global.city).length > 0 ? Global.city.name : $t("select")}}
                <div class="btn-arrow"><svg fill="#ff5011" height="12" width="12" viewBox="0 0 24 24" role="img" class="pull-right"><path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path></svg></div>
              </button>

              <div id="detail-city-options" aria-labelledby="detail-city-btn" class="dropdown-menu basic">
                <a v-for="(city, index) in listcity" v-bind:key="'privince-' + index" data-selenium-name="city" :data-selenium-value="city.name" class="dropdown-item" href="javascript:void(0);" @click="completeQuestion().city(city)">{{city.name}}</a>
              </div>
            </div>
          </div>
      </div> -->

      
      <div class="row incomplete" id="detail-questions" v-show="stepCompleted('income')">
        <div class="col">
          <template v-for="(groupedFilteredQuestion, groupedFilteredIndex) in groupedFilteredQuestions" :key="'grouped-filtered-question-' + groupedFilteredIndex">
            <div class="row p-25vh" v-bind:id="'grouped-filtered-question-' + groupedFilteredIndex" v-if="displayQuestionIndex < 0 || groupedFilteredIndex <= displayQuestionIndex">
              <div class="col-12">
                <h2 class="h6 text-center text-default mb-3 mr-3 ml-3">
                  {{Global.quote.prefLang === "en" ? groupedFilteredQuestion[0].questionText1En : groupedFilteredQuestion[0].questionText1Th}}
                  <a href="javascript:void(0)" class="question-mark" data-toggle="tooltip" data-placement="top" :title="questionTooltip(groupedFilteredQuestion)" :data-original-title="questionTooltip(groupedFilteredQuestion)" >?</a>
                </h2>
                <div class="row text-default">
                  <div class="col-12" v-for="(question, index) in groupedFilteredQuestion" v-bind:key="'question-text2-' + index">
                    <p class="text-center">{{Global.quote.prefLang === "en" ? question.questionText2En : question.questionText2Th}}</p>
                  </div>
                </div>
                <div class="row pt-3" v-if="['PAQ01', 'TMQ007'].includes(groupedFilteredQuestion[0].questionCode)">
                  <div class="col-12">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="Yes" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'Yes' ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'Yes');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'Yes');">{{$t("yesWithSOMPO")}}
                    </button>
                  </div>
                  <div class="col-12">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="No10" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'No' &&  Global.TMQ007 ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'No');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'No'); Global.TMQ007 = true;">{{$t("yesWithOtherCompanies")}}
                    </button>
                  </div>
                  <div class="col-12">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="No" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'No' &&  !Global.TMQ007 ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'No');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'No'); Global.TMQ007 = false;">{{$t("question_no_policy")}}
                    </button>
                  </div>
                </div>
                <div class="row pt-3" v-else-if="groupedFilteredQuestion[0].questionCode === 'PAQ03'">
                  <div class="col-12 col-md-12">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="No" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'No' ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'No');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'No');">{{$t("question_no")}}</button>
                  </div>
                  <div class="col-12 col-md-12">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="Yes1" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'Yes1' ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'Yes1');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'Yes1');">{{ $t("question_yes_1time") }}
                    </button>
                  </div>
                  <div class="col-12 col-md-12">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="Yes" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'Yes' ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'Yes');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'Yes');">{{$t("question_yes_more")}}
                    </button>
                  </div>
                </div>
                <div class="row pt-3" v-else>
                  <div class="col-12 col-md-6">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="Yes" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'Yes' ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'Yes');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'Yes');">{{ $t("question_yes") }}
                    </button>
                  </div>
                  <div class="col-12 col-md-6">
                    <button class="btn usage4button" :data-selenium-name="'productQuestion-' + groupedFilteredQuestion[0].questionCode" data-selenium-value="No" v-bind:class="[groupedFilteredQuestion[0].Response_Value__c ? (groupedFilteredQuestion[0].Response_Value__c === 'No' ? 'choose4yes' : 'choose4no') : 'to2do']" v-on:click="setQuestionResponses(groupedFilteredQuestion, groupedFilteredIndex, 'No');eventLog('HealtQuestion_' + groupedFilteredQuestion[0].questionCode, 'No');">{{$t("question_no")}}</button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="row" id="submit-quote"  v-show="stepCompleted('detail-questions', true)" style="margin-top: 30vh; padding-bottom: 30vh;" >
        <div class="col-12">
          <!-- <div class="tooltip2extra fadeIn">
              <div class="tooltip2extra">{{$t("oneMoreStep")}}</div>
              <div class="arrow2extra"></div>
          </div> -->

          <div class="text-center mb-2">
            <picture>
              <source media="(min-width: 1280px)" srcset="images/onemore2health2x.png">
              <img loading="lazy" src="images/onemore2health.png" class="mx-auto" alt="one more click">
            </picture>
          </div>
          <input  id="bsubmit-quote" style="opacity: 0; height: 0;"/>
          <button class="btn btn-block btn-lg btn-link btn-link-default buy-now py-3"
            data-selenium-name="getQuote" @click="submit(); eventTracking('quote','quotationInput', 'Get_price', 'PA');" :disabled="!isCompleted">
            {{ $t("getYourQuote") }}
            <svg fill="#FFFFFF" height="15" width="15" viewBox="1 0 24 24" role="img" style="transform: rotate(-90deg);">
              <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
            </svg>
          </button>
        </div>
        <div class="col-12 pt-3 text-center">
          {{ $t("acceptAndNext") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import noUiSlider from "nouislider";
import dayjs from "@/dayjs.js";
import NewSelect from "@/components/SelectComponent.vue";

export default {
  data() {
    return {
      Global,
      salaryRange:[3000001,6000000,13000000,20000000,30000000,60000000,85000000,85000001],
      slider: null,
      seleniumIncome:0,
      dobDate: Global.phaccount.dob ? dayjs(Global.phaccount.dob, "DD/MM/YYYY").format("DD") : "",
      dobMonth: Global.phaccount.dob ? dayjs(Global.phaccount.dob, "DD/MM/YYYY").format("MM") : "",
      dobYear: Global.phaccount.dob ? dayjs(Global.phaccount.dob, "DD/MM/YYYY").format("YYYY") : "",
      progressBar:5,
      listprovince: [],
      listcity: [],
      filteredQuestions: [],
      groupedFilteredQuestions: [],
      defaultphaccount: {
        firstName: "",
        lastName: "",
        email: "",
        PersonHasOptedOutOfEmail: true,
        sendWaStatus: '',
        thaiIdNumber: "",
        phoneNumber: "",
        residentStatus: "",
        passPortNumber: "",
        countryOfIssuance: "",
        dob: "",
        driverAge: "",
        gender: "",
        maritialStatus: "",
        height: "",
        weight: "",
        smoker: "",
        postalCode: "",
        province: "",
        provinceCode: "",
        district: "",
        districtCode: "",
        subDistrictCode: "",
        subDistrict: "",
        soi: "",
        village: "",
        houseNumber: "",
        moo: "",
        occupation: {},
        Educational_Qualification: "",
        Company_Revenue: "",
      },
      optionOccupation: false,
      showOccupationList: false,
      showList: false,
    };
  },
  components: {
    NewSelect
  },
  mixins: [Mixin],
  name: "PA-BasicInfo",
  beforeRouteLeave (to, from, next) {
    let bypass = ['pa-packages','payment']
    if(bypass.includes(to.name)) next()
    if(window.pageYOffset > 0) {
      this.scrollToTopPage()
      next(false)
    } else next()
  },
  async created() {
    window.onbeforeunload = (e) => {
      if(window.pageYOffset > 0) {
        this.scrollToTopPage()
        return false
      }
      return "";
    }
    document.title = "Personal Accident Insurance";
    Global.quote.productType = Global.enumProductType.PA;
    if (this.$route.query.utm_source !== undefined){
      Global.utmSource = this.$route.query.utm_source;
    }
    if (this.$route.query.channel !== undefined){
      Global.decryptReferralLink = this.$route.query.channel
    }
    if(this.$route.query.productAffiliate !== undefined){
      Global.productAffiliate = this.$route.query.productAffiliate
    }
    if(this.$route.query.useUrl !== undefined){
      Global.useUrl = this.$route.query.useUrl
    }
    // Reset all question
    // this.resetForm();

    var vm = this,
      nIntervId,
      countInterval = 0,
      nIntervId = setInterval(async function() {
      countInterval++;
      vm.setVariantTesting();
      if (
        (Global.restServer.token !== "" && Global.roojaiService.token !== "") ||
        countInterval === 10
      ) {
        clearInterval(nIntervId);
        await vm.getConfigurations();
        if (Global.quote.opportunityNumber === "TBA") {
          Global.quote.opportunityNumber =
            "TBA" + Global.serverTime.replace(/\s|:|-/g, "");
        }
        if(Global.decryptReferralLink && Global.decryptReferralLink != ""){
          await vm.setLeadSource()
        }
        vm.eventLog("Quote start", "Quote start");
        if(Global.quote.productType && Global.quote.productType != Global.enumProductType.Health){
          vm.$router.replace(Global.productConfig.route.basicinfo);
        }
        vm.getOccupations(Global.quote.productType, "SOMPO");
        // else vm.getOccupationsV2(Global.quote.productType, "SOMPO");
        var category = "&c=" + Global.coverName.PA;
        await vm.getProductQuestions("2", category);
        vm.hardBlockQuestions = Global.productQuestions.filter(function(
            productQuestion
          ) {
            return Global.questionResponses.find(
              questionResponse =>
                questionResponse.Question_Code__c === productQuestion.questionCode &&
                questionResponse.Response_Value__c === "Yes" &&
                productQuestion.hardBlock
            );
          });
         vm.filteredQuestions = vm.filterQuestions(Global.productQuestions);

         vm.filteredQuestions.forEach(function(filteredQuestion) {
           var found = Global.questionResponses.find(
             questionResponse =>
               questionResponse.Question_Code__c === filteredQuestion.questionCode
           );
           if (found) {
             filteredQuestion.Response_Value__c = found.Response_Value__c;
           }
         });
        vm.groupedFilteredQuestions = vm.groupQuestions(vm.filteredQuestions);
        vm.setDisplayQuestionIndex();
      }
    }, 1000);
  },
  mounted() {
    this.testingB = this.$route.query.variant == 1;
    if (Global.productConfigBefore !== null && Global.productConfigBefore.param !== Global.productConfig.param) {
      Global.productConfigBefore = null;
      // this.resetForm();
    }
    Global.productConfigBefore = Global.productConfig;

    this.eventTracking('startQuote', 'quotationClick', 'Startquote', 'PA');
    window.scrollTo(0, 0);

    Global.quote.salary = Global.quote.salary == '' ? '0' : Global.quote.salary;

    if(this.$route.query.lang !== undefined && ["en", "id"].indexOf(this.$route.query.lang.toLowerCase()) > -1){
        Global.quote.prefLang = this.$route.query.lang.toLowerCase() == 'th' ? 'id' : this.$route.query.lang.toLowerCase();
        this.$root.$i18n.locale = Global.quote.prefLang;
      }

    if( !this.slider?.noUiSlider ){
        this.slider = document.getElementById('slider-income');

        noUiSlider.create(this.slider, {
            start: 0,
            connect: [true,false],
            snap: true,
            range: {
                'min':0,
                '17%':6000000,
                '34%':13000000,
                '51%':20000000,
                '68%':30000000,
                '79%':60000000,
                '90%':85000000,
                'max': 85000001
            }
        });

        this.slider.noUiSlider.set(Global.quote.salary);

        this.slider.noUiSlider.on('change', function (values, handle) {
            Global.quote.salary = values[0] ? parseInt(values[0]).toString() : "0";
        });
    }

    if( this.stepCompleted('income') ){
        this.progressBar = 90;
    }
    //start move to updated()
      // $(window).off('scroll'); /* remove event to prevent duplicated binding */
      // $(window).on("scroll", function() {
      //     if( $(window).scrollTop() > 150 ){
      //         $("#progress-line-area").addClass("fixed");
      //     }else{
      //         $("#progress-line-area").removeClass("fixed");
      //     }
      // });
      // $('[data-toggle="tooltip"]').tooltip();

      // $('#tooltip-first').tooltip('show');
      //   setTimeout(function(){ 
      //     $('#tooltip-first').tooltip('hide'); 
      //   }, 3000);
    //end move to update()
    
  },
  updated() {
    var vm = this;
    if(vm.stepCompleted('detail-questions', true)){
      vm.progressBar = 100;
      var isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
      if(isiOS){
        vm.moveTo("#submit-quote");
      }
    }
    // $('[data-toggle="tooltip"]').tooltip("dispose");
    // $('[data-toggle="tooltip"]').tooltip();

    // $(window).off('scroll'); /* remove event to prevent duplicated binding */
    // $(window).on("scroll", function() {
    //     if( $(window).scrollTop() > 150 ){
    //         $("#progress-line-area").addClass("fixed");
    //     }else{
    //         $("#progress-line-area").removeClass("fixed");
    //     }
    // });
    // $('[data-toggle="tooltip"]').tooltip();
    // if(!this.stepCompleted('gender')){//run only if gender not ready set
    //   $('#tooltip-first').tooltip('show');
    //   setTimeout(function(){ 
    //     $('#tooltip-first').tooltip('hide'); 
    //   }, 3000);
    // }
  },
  methods: {
    resetForm: function(){
      Global.steps = [];
      Global.productConfig = null
      Global.quote.productType = ""
      Global.quote.postalCode = "";
      Global.occupations = null
      Global.questionResponses = [];
      Global.quote.salary = "0";
      Object.assign(Global.phaccount, this.defaultphaccount);
      Global.productConfig = Global.productConfigData.find(p => p.param === "pa");
      Global.quote.productType = Global.productConfig.productType;
      this.dobInputDate = ''
      this.dobInputMonth = ''
      this.dobInputYear = ''
    },
    handleBlurDate(event){
      if (event.target.value.length == 1) {
        this.dobDate = ("0"+event.target.value)
        
        var date = this.dobDate + '/' + this.dobMonth + '/' + this.dobYear; 
        if( this.validate(date).isDOB() ) {
          Global.phaccount.dob = date;
        }else{
          Global.phaccount.dob = "";
        }
      }else{
        this.dobDate = event.target.value.replace(/[^0-9]/g, '');

        var date = this.dobDate + '/' + this.dobMonth + '/' + this.dobYear; 
        if( this.validate(date).isDOB() ) {
          Global.phaccount.dob = date;
        }else{
          Global.phaccount.dob = "";
        }
      }
    },
    handleBlurMonth(event){
      if (event.target.value.length == 1) {
        this.dobMonth = ("0"+event.target.value)
        
        var date = this.dobDate + '/' + this.dobMonth + '/' + this.dobYear; 
        if( this.validate(date).isDOB() ) {
          Global.phaccount.dob = date;
        }else{
          Global.phaccount.dob = "";
        }
      }else{
        this.dobMonth = event.target.value.replace(/[^0-9]/g, '');

        var date = this.dobDate + '/' + this.dobMonth + '/' + this.dobYear; 
        if( this.validate(date).isDOB() ) {
          Global.phaccount.dob = date;
        }else{
          Global.phaccount.dob = "";
        }
      }
    },
    triggerPostcode(event){
      if(event.target.value.length === 5){
        this.checkPostalCode();
        this.eventLog("Postal Code", event.target.value);
      }
    },
    checkPostalCode: async function() {
      if (Global.quote.postalCode.length === 5) {
        await this.getProvince();
        if (Global.isValidated.postcode === true) {
          this.completeQuestion().postcode();
          Global.phaccount.postalCode = Global.quote.postalCode
        }
        else {
          Global.isValidated.postcode = false;
        }
      }
    },
    getProvince: async function() {
      try {
        this.loading("show");
        var response = await this.request().get(
          Global.restServer.url +
          "/postals?search=province&keyword=" +
          Global.quote.postalCode
        );

        this.loading("hide");
        if (response.data?.model?.found === true) {
          Global.isValidated.postcode = true;
        } else {
          Global.isValidated.postcode = false;
        }
      } catch (error) {
        if(error.response){
          vm.frontEndLog("postals","search=province&keyword=" + Global.quote.postalCode, error.response.status.toString())
        }
        this.loading("hide");
        Global.isValidated.postcode = false;
        console.error("getProvince => ", error);

      }
    },
    getProvinceList: async function() {
      try {
        this.loading("show");
        var response = await this.request().get(
          Global.restServer.url +
          "/postals?search=provincelist"
        );

        this.loading("hide");
        if (response.data?.model?.found === true) {
          this.listprovince = response.data.model.provinces
          Global.isValidated.postcode = true;
        } else {
          Global.isValidated.postcode = false;
        }
      } catch (error) {
        if(error.response){
          vm.frontEndLog("postals","search=province&keyword=" + Global.quote.postalCode, error.response.status.toString())
        }
        this.loading("hide");
        Global.isValidated.postcode = false;
        console.error("getProvince => ", error);

      }
    },
    getCity: async function(province) {
      try {
        this.loading("show");
        var response = await this.request().get(
          Global.restServer.url +
          "/postals?search=citylistbyprovince&keyword=" + province
        );

        this.loading("hide");
        if (response.data?.model?.found === true) {
          this.listcity = response.data.model.cities
          Global.isValidated.postcode = true;
        } else {
          Global.isValidated.postcode = false;
        }
      } catch (error) {
        if(error.response){
          vm.frontEndLog("postals","search=citylistbyprovince&keyword" + Global.quote.postalCode, error.response.status.toString())
        }
        this.loading("hide");
        Global.isValidated.postcode = false;
        console.error("citylist => ", error);

      }
    },
    setGender: function(gender, status) {
      var vm = this;
      Global.phaccount.gender = gender;
      Global.phaccount.maritialStatus = status;

      vm.pushStep("gender");
      vm.progressBar = 15;

      if (gender && status) {
        if(!vm.isMobile()){
          vm.moveTo("#input-dob");
        }
        var input = document.getElementById("dd-dob")
        this.focusTextInputPA(input);
      }
      vm.getOccupations(Global.quote.productType, "SOMPO");
      // else vm.getOccupationsV2(Global.quote.productType, "SOMPO");

    },
    focusTextInputPA: function(el) {
      var __tempEl__ = document.createElement('input');

        if (el) {
            // Function to focus on the target element and remove the observer
            function focusOnElementAndCleanup() {
                el.focus();
                el.click();
                // Conditionally check these two as we only set them up when the target input is invisible.
                if (document.body.contains(__tempEl__)) { document.body.removeChild(__tempEl__); } // Remove the temporary element
                if (observer) { observer.disconnect(); }// Cleanup the observer
            }

            // Check if the target element is already visible
            if (isVisible(el)) {
                focusOnElementAndCleanup();
            } else {
                focusOnDummyElementToOpenIOSKeyboard();
                // Create a MutationObserver to watch for changes in the DOM
                var observer = new MutationObserver(function (mutationsList) {
                    for (var mutation of mutationsList) {
                        if (mutation.type === 'childList' && isVisible(el)) {
                            focusOnElementAndCleanup();
                            break;
                        }
                    }
                });

                // Start observing changes in the parent node (you can change this to a more appropriate parent)
                observer.observe(document.body, { childList: true, subtree: true });
            }

            // Create a temporary input element to focus on and open the keyboard
            function focusOnDummyElementToOpenIOSKeyboard() {
                __tempEl__.style.position = 'absolute';
                __tempEl__.style.top = (el.offsetTop + 7) + 'px';
                __tempEl__.style.left = el.offsetLeft + 'px';
                __tempEl__.style.height = 0;
                __tempEl__.style.opacity = 0; // Set opacity to 0 to make it invisible
                document.body.appendChild(__tempEl__);
                __tempEl__.focus();
                setTimeout(() => {
                  focusOnElementAndCleanup();
                }, 100);
            }

        }


        // Function to check if the element is visible in the DOM
        function isVisible(element) {
            return element && element.offsetParent !== null;
        }
    },
    submit: async function() {
      this.getPlanByProduct("/pa-packages");
    },
    completeQuestion: function() {
      var methods = {};
      var vm = this;

      methods.gender = function() {
        if (Global.phaccount.gender) {
            if( !vm.stepCompleted('gender') ){
                vm.progressBar = 15;
            }

            vm.eventLog('Gender_status', Global.phaccount.gender);
            vm.pushStep("gender");

            if( $(window).width() < 768 ){ // for Mobile
                setTimeout(function(){
                vm.moveTo("#input-dob");
                },500)
            }else{
                vm.moveTo("#input-dob", function() {
                    $("#dd-dob").focus();
                });
            }


            vm.getOccupations(Global.quote.productType, "SOMPO");
            // else vm.getOccupationsV2(Global.quote.productType, "SOMPO");
        }
      };

      methods.dob = function() {
        if (vm.isDOBvalid) {
            if( !vm.stepCompleted('dob') ){
                vm.progressBar = 30;
            }

            Global.phaccount.driverAge = vm.dob2age(Global.phaccount.dob);
            vm.eventLog("dob", Global.phaccount.dob);
            vm.pushStep("dob");

            if( $(window).width() < 767 ){ // for Mobile
                $("#dob").blur();
                setTimeout(function(){
                  vm.moveTo("#input-occupation");
                  vm.showOccupationList = true;
                },500)
            }else{
                vm.moveTo("#input-occupation");
                vm.showOccupationList = true;
            }
        }
      };

      methods.occupation = function() {
        if (Global.phaccount.occupation) {
          // if (Global.phaccount.occupation.nameTh == "Pemilik Bisnis") {
          //     vm.pushStep("occupation");
          //     // vm.moveTo("#submit-quote");
          //     vm.progressBar = 60;
          //     vm.moveTo("#info-gross");
          //   }
          //   else if (Global.phaccount.occupation.nameTh == "Freelance") {
          //     vm.pushStep("occupation");
          //     // vm.moveTo("#submit-quote");
          //     vm.progressBar = 60;
          //     vm.moveTo("#info-educational");
          //   }
          //   else{
              if( !vm.stepCompleted('occupation') ){
                vm.progressBar = 60;
              }
              vm.eventLog('occupation', Global.phaccount.occupation.nameEn);
              vm.pushStep("occupation");
              vm.moveTo("#input-income");
            // }
        }
      };

      methods.income = function() {
            if( !vm.stepCompleted('income') || vm.progressBar != 100 ){
                vm.progressBar = 60;
            }
            vm.eventLog('income', Global.quote.salary);
            vm.pushStep("income");
              // vm.moveTo("#submit-quote");
              vm.progressBar = 70;
              vm.getProvinceList();
              vm.moveTo("#detail-questions");    
      };

      methods.postcode = function() {
              vm.pushStep("postalcode");
              // vm.moveTo("#submit-quote");
              vm.progressBar = 80;
              vm.moveTo("#detail-questions");
      };

      methods.province = function(province) {
        Global.province = province
        Global.city = []
        vm.pushStep("detail-province");
        vm.getCity(province.name);
        // vm.moveTo("#submit-quote");
        vm.progressBar = 70;
        vm.eventLog("Select Province", province.name);
        vm.moveTo("#detail-address-city");
      };

      methods.city = function (city) {
        Global.city = city
        vm.pushStep("detail-city");
        // vm.moveTo("#submit-quote");
        vm.progressBar = 80;
        vm.eventLog("Select City", city.name);
        vm.moveTo("#detail-questions");
      }

      methods.question = function() {
        setTimeout(function() {
          if (
            vm.displayQuestionIndex > -1 &&
            vm.displayQuestionIndex < vm.filteredQuestions.length
          ) {
            vm.moveTo("#grouped-filtered-question-" + vm.displayQuestionIndex);
          } else if (vm.stepCompleted("detail-questions")) {
            vm.progressBar = 100;
            vm.moveTo("#submit-quote");
          }
        }, 100);
      };

      return methods;
    },
    filterQuestions: function(questions) {
      var vm = this;

      return questions.filter(function(question) {
        return !vm.hardBlockQuestions.find(
          hardBlockQuestion =>
            hardBlockQuestion.questionCategory === question.questionCategory &&
            question.questionNumber > hardBlockQuestion.questionNumber
        );
      });
    },
    setDisplayQuestionIndex: function() {
      this.displayQuestionIndex = this.groupedFilteredQuestions.findIndex(
        function(groupedFilteredQuestion) {
          return groupedFilteredQuestion.some(
            question => !question.Response_Value__c
          );
        }
      );
    },
    setStepDetailQuestions: function() {
      var hardBlockCategory = [],
        groupedByCategory = this.filteredQuestions.reduce(function(
          accumulator,
          currentValue
        ) {
          var key = currentValue.questionCategory;

          if (
            currentValue.hardBlock &&
            currentValue.Response_Value__c === "Yes"
          ) {
            hardBlockCategory.push(key);
          }

          if (!hardBlockCategory.includes(key)) {
            if (!accumulator[key]) {
              accumulator[key] = [];
            }
            accumulator[key].push(currentValue);
          } else {
            delete accumulator[key];
          }
          return accumulator;
        },
        {});

      var countPassedCategory = 0;
      Object.values(groupedByCategory).forEach(function(category) {
        var haveResponse = category.every(
          question => question.Response_Value__c
        );

        if (haveResponse) {
          countPassedCategory += 1;
        }
      });

      if (
        countPassedCategory === Object.keys(groupedByCategory).length &&
        countPassedCategory > 0
      ) {
        this.pushStep("detail-questions");
      } else {
        Global.steps = Global.steps.filter(step => step !== "detail-questions");
      }
    },
    setQuestionResponses: function(
      groupedFilteredQuestion,
      groupedFilteredIndex,
      Response_Value__c
    ) {
      var vm = this,
        blockByTMQ050 = false,
        blockByAnother = false;
      this.blockSubmitBtn = false;
      groupedFilteredQuestion.forEach(function(question) {
        question.Response_Value__c = Response_Value__c;

        if (question.hardBlock) {
          vm.hardBlockQuestions = vm.hardBlockQuestions.filter(
            hardBlockQuestion =>
              hardBlockQuestion.questionCode !== question.questionCode
          );
          if (question.Response_Value__c === "Yes") {
            vm.hardBlockQuestions.push(question);

            if (question.questionCode === "TMQ050") {
              blockByTMQ050 = true;
            } else {
              blockByAnother = true;
            }
          }
        }

        Global.questionResponses = Global.questionResponses.filter(function(
          questionResponse
        ) {
          return questionResponse.Question_Code__c !== question.questionCode;
        });
        if (question.Response_Value__c === "Yes1") {
          var questionResponse = {
            Question_Code__c: question.questionCode,
            Question_id__c: question.sfid,
            Response_Value__c: 'No'
          };
        }else{
          var questionResponse = {
            Question_Code__c: question.questionCode,
            Question_id__c: question.sfid,
            Response_Value__c: Response_Value__c
          };
        }
        Global.questionResponses.push(questionResponse);
      });

      this.groupedFilteredQuestions[
        groupedFilteredIndex
      ] = groupedFilteredQuestion;

      var flattened = this.groupedFilteredQuestions.flat();

      this.filteredQuestions = this.filterQuestions(Global.productQuestions);

      var newFilteredQuestions = [];
      this.filteredQuestions.forEach(function(question) {
        var found = flattened.find(
          flat => flat.questionCode === question.questionCode
        );
        newFilteredQuestions.push(found || question);
      });

      this.groupedFilteredQuestions = this.groupQuestions(newFilteredQuestions);

      this.setDisplayQuestionIndex();

      var unblockQuestion = vm.filteredQuestions.find(function(
        filteredQuestion
      ) {
        return !vm.hardBlockQuestions.some(
          hardBlockQuestion =>
            filteredQuestion.questionCategory ===
            hardBlockQuestion.questionCategory
        );
      });

      if (blockByTMQ050 || (!unblockQuestion && blockByAnother)) {
        this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2"))
        Global.quote.onlineStage = "Questionnaire";
        this.blockSubmitBtn = true;
        this.saveQuote();
      }

      this.setStepDetailQuestions();

      this.completeQuestion().question();
    },
    openOccupation() {
      this.optionOccupation = !this.optionOccupation;
    },
    selectOcuppation(value) {
      this.phaccountOccupation = value;
      this.optionOccupation = false;
    }
  },
  watch:{
    progressBar: (value) => {
      if(value > 90){
        var input = document.getElementById("bsubmit-quote")
        input.focus()
        input.blur()
      }
    },
    seleniumIncome:function(value){
      value = parseInt(value);
      value = this.salaryRange.includes(value) ? value : 0;
      Global.quote.salary = value.toString();
      this.slider.noUiSlider.set(value);
    },
    dobDate:function(value){
      const num = value.replace(/\D/g, '');
      if(num.length == 2 && num > 31){
        this.dobDate = ""
      }
      else if(num.length == 2){
        $("#mm-dob").focus();
      }
    },
    dobMonth:function(value){
      const num = value.replace(/\D/g, '');
      if(num.length == 2 && num > 12){
        this.dobMonth = ""
      }
      else if(num.length == 2){
        $("#yyyy-dob").focus()
      }
    },
    showOccupationList: function(){
      if(this.showOccupationList){
        setTimeout(() => {
          this.showList = true;
        }, 200);
      } else {
        this.showList = false;
      }
    }
  },
  computed: {
    sortingOccupation: function(){
      if (Global.occupations) {
        if (Global.quote.prefLang == 'id') {
          let label = ["Pelajar","Ibu Rumah Tangga / Suami / Pensiunan","Freelance","Pemilik Bisnis","Pekerja Kantoran (Karyawan / Management) / Pegawai Negeri / Guru"]
          let sorts =  Global.occupations.sort((a,b) => (a.nameTh > b.nameTh) ? 1 : ((b.nameTh > a.nameTh) ? -1 : 0))
          for (let i = 0; i < label.length; i++) {
            sorts.sort(function(x,y){ return x.nameTh == label[i] ? -1 : y.nameTh == label[i] ? 1 : 0; })
          }
          return sorts
        }else if (Global.quote.prefLang == 'en'){
          let label = ["Student","Housewife / Househusband / Retiree","Freelance","Business Owner","Office Worker (employee/management) / Public Servant / Teacher"]
          let sorts =  Global.occupations.sort((a,b) => (a.nameEn > b.nameEn) ? 1 : ((b.nameEn > a.nameEn) ? -1 : 0))
          for (let i = 0; i < label.length; i++) {
            sorts.sort(function(x,y){ return x.nameEn == label[i] ? -1 : y.nameEn == label[i] ? 1 : 0; })
          }
          return sorts
        }
      }
    },
    occupationV2Data() {
      return this.sortingOccupationsV2();
    },
    sex2status: function() {
      return Global.phaccount.gender + Global.phaccount.maritialStatus;
    },
    isDOBvalid: function() {
      return this.validate(Global.phaccount.dob).isDOB();
    },
    isCompleted: function() {
      var arr = [
        Global.phaccount.gender != "",
        this.isDOBvalid,
        Object.keys(Global.phaccount.occupation).length !== 0
      ];

      return !arr.includes(false);
    },
    selectedSalaryRange: function(){
      if( !this.salaryRange.includes(parseInt(Global.quote.salary)) ){
        return '';
      }

      if( parseInt(Global.quote.salary) == 6000000 ){
        var end = parseInt(Global.quote.salary);
        var index = this.salaryRange.indexOf(parseInt(Global.quote.salary)) - 1;
        var start = this.salaryRange[index];
        return this.formatNumber(start) + ' - ' + this.formatNumber(end);
      }

      if( parseInt(Global.quote.salary) == 85000001 ){
        return (Global.quote.prefLang == 'id' ? this.$t("andMore") : '') + ' 85.000.000 ' + (Global.quote.prefLang != 'id' ? this.$t("andMore") : '');
      }

      var end = parseInt(Global.quote.salary);
      var index = this.salaryRange.indexOf(parseInt(Global.quote.salary)) - 1;

      if( !this.salaryRange[index] ){
        return '';
      }

      var start = this.salaryRange[index] + 1;

      return this.formatNumber(start) + ' - ' + this.formatNumber(end);
    },
    phaccountOccupation: {
      set: function(newValue) {
        if(newValue?.occupationGroup == 4){
          Global.phaccount.occupation = {};
          this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2"));
        }else{
          Global.phaccount.occupation = newValue;
        }
      },
      get(){
        return Global.phaccount.occupation;
      }
    },
    dobInputDate:{
      set:function(value){
        this.dobDate = value.replace(/[^0-9]/g, '');

        var date = this.dobDate + '/' + this.dobMonth + '/' + this.dobYear;
        if( this.validate(date).isDOB() ) {
          Global.phaccount.dob = date;
        }else{
          Global.phaccount.dob = "";
        }
      },
      get:function(){
        return this.dobDate;
      }
    },
    dobInputMonth:{
      set:function(value){
        this.dobMonth = value.replace(/[^0-9]/g, '');

        var date = this.dobDate + '/' + this.dobMonth + '/' + this.dobYear;
        if( this.validate(date).isDOB() ) {
          Global.phaccount.dob = date;
        }else{
          Global.phaccount.dob = "";
        }
      },
      get:function(){
        return this.dobMonth;
      }
    },
    dobInputYear:{
      set:function(value){
        this.dobYear = value.replace(/[^0-9]/g, '');

        var date = this.dobDate + '/' + this.dobMonth + '/' + this.dobYear;
        if( this.validate(date).isDOB() ) {
          Global.phaccount.dob = date;
        }else{
          Global.phaccount.dob = "";
        }
      },
      get:function(){
        return this.dobYear;
      }
    },
  },
  beforeUnmount() {
    if( this.slider?.noUiSlider ){
      this.slider.noUiSlider.destroy();
    }

    $(window).off('scroll');
  }
};
</script>

<i18n>
{
  "en": {
    "question_yes_1time": "Yes, 1 time",
    "question_yes_more": "Yes, more than 1 time",
  },
  "id": {
    "question_yes_1time": "Ya, 1 kali",
    "question_yes_more": "Ya, lebih dari 1 kali",
  },
}
</i18n>